const AppNavSearch = (props: { searchInput: string, setSearchInput: (value: string) => void }) => {
  // TODO set app-nav-search to visible and implement dynamic search
  return (
    <form className="app-nav-search hidden">
      <i>
        <svg className="svg-use">
          <use xlinkHref="#icon-search"></use>
        </svg>
      </i>
      <input type="text" className="input" value={props.searchInput} onChange={(e) => props.setSearchInput(e.target.value)}></input>
    </form>
  );
};

export default AppNavSearch;
