import { createContext, useContext } from "react";

export type OfferContextType = {
  offer: string;
  setOffer: (value: string) => void;
};

export const OfferContext = createContext<OfferContextType>({
  offer: "",
  setOffer: () => {},
});

export const useOfferContext = () => useContext(OfferContext);
