import { Outlet } from "react-router-dom";

const AppMain=()=> {
    return (
      <div className="app-main">
        <div className="app-main-wrapper">
          <Outlet />
        </div>
      </div>
    );
};

export default AppMain;