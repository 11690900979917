import { Link, useParams } from "react-router-dom";
import { SimpleConsultantProfile } from "../search/ConsultantsForSearchBarContext";

const ProfileTab = (props: { consultantData: SimpleConsultantProfile }) => {
  const { id } = useParams();
  // TODO move into onEffect block
  var activeFlag = "";
  if (typeof id !== "undefined" && id === props.consultantData.id) {
    activeFlag = " --active";
  }

  // TODO move into onEffect block
  var matchLevel = "";
  if (props.consultantData.score >= 80) {
    matchLevel = " --high";
  } else if (
    props.consultantData.score < 80 &&
    props.consultantData.score >= 60
  ) {
    matchLevel = " --medium";
  } else {
    matchLevel = " --low";
  }

  const linkToPage = "/profile/" + props.consultantData.id;
  return (
    <Link
      to={linkToPage}
      style={{ color: "inherit", textDecoration: "inherit" }}
    >
      <div className={"profile-tab" + activeFlag}>
        <span className="name">
          {props.consultantData.name}
          <br />
        </span>
        <span>{props.consultantData.title}</span>
        <div className="profile-matching">
          <div
            className={"match-level" + matchLevel}
            style={{
              visibility:
                props.consultantData.score === 0 ? "hidden" : "visible",
            }}
          >
            {props.consultantData.score} <span>%</span>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default ProfileTab;
