const Icons = () => {
  return (
    <svg className="hidden">
      <symbol id="icon-link" viewBox="0 0 16.71 16.71">
        <path d="M1.41 16.71 0 15.29 13.29 2H.71V0h16v16h-2V3.41l-13.3 13.3z" />
      </symbol>

      <symbol id="icon-arrow-down" viewBox="0 0 26.83 14.83">
        <path d="M14.02 14.83 0 1.45 1.38 0l12.57 12L25.38 0l1.45 1.38-12.81 13.45z" />
      </symbol>

      <symbol id="icon-arrow-back" viewBox="0 0 25 25">
        <path d="M12.5 25 0 12.5 12.5 0l2.24 2.24-8.66 8.67H25v3.18H6.08l8.66 8.67L12.5 25z" />
      </symbol>

      <symbol id="icon-arrow-to" viewBox="0 0 25 25">
        <path d="M12.5,0L25,12.5L12.5,25l-2.2-2.2l8.7-8.7H0l0-3.2h18.9l-8.7-8.7L12.5,0z" />
      </symbol>

      <symbol id="icon-file" viewBox="0 0 20 21.09">
        <path d="M5.93 21.09c-1.1 0-2.06-.42-2.75-1.07-1.51-1.43-1.85-4.19.39-6.71 3.39-3.8 8.08-8.1 8.28-8.28l1.84 2.01S8.9 11.43 5.6 15.13c-1.14 1.28-1.09 2.41-.55 2.91.54.52 1.72.48 3.01-.8 4.57-4.54 8.24-8.45 8.74-9.3 1.01-1.72.25-3.44-.96-4.39-1.34-1.05-3.56-1.41-5.75.73C8.17 6.15 6.48 7.87 5 9.37c-1.16 1.18-2.19 2.22-3.09 3.1l-1.9-1.96c.89-.87 1.91-1.9 3.06-3.06 1.49-1.51 3.2-3.24 5.13-5.12 3.24-3.16 7.03-2.74 9.34-.93 2.53 1.98 3.2 5.24 1.63 7.92-.96 1.63-7.26 7.95-9.16 9.85-1.38 1.37-2.81 1.92-4.06 1.92Z" />
      </symbol>

      <symbol id="icon-close" viewBox="0 0 18 18">
        <path d="M9.44 10.41.31 1.72A.996.996 0 0 1 .28.31.987.987 0 0 1 1.69.28l7.68 7.31L16.28.31a.996.996 0 1 1 1.45 1.37l-8.29 8.73Z" />
        <path d="M17 18a.97.97 0 0 1-.72-.31l-6.91-7.27-7.68 7.31a.996.996 0 1 1-1.38-1.44l9.13-8.7 8.29 8.73c.38.4.36 1.03-.04 1.41-.19.18-.44.28-.69.28Z" />
      </symbol>

      <symbol id="icon-info" viewBox="0 0 27.48 27.48">
        <path d="M13.74 27.48C6.16 27.48 0 21.32 0 13.74S6.16 0 13.74 0s13.74 6.16 13.74 13.74-6.16 13.74-13.74 13.74Zm0-25.19c-6.31 0-11.45 5.14-11.45 11.45s5.14 11.45 11.45 11.45 11.45-5.14 11.45-11.45S20.05 2.29 13.74 2.29Z" />
        <path d="M12.6 6.87h2.29v7.44H12.6z" />
        <circle cx="13.74" cy="18.32" r="1.15" />
      </symbol>

      <symbol id="icon-search" viewBox="0 0 16.21 16.21">
        <path d="m15.96 14.59-3.68-3.68a6.835 6.835 0 0 0 1.36-4.09C13.64 3.06 10.58 0 6.82 0S0 3.06 0 6.82s3.06 6.82 6.82 6.82c1.47 0 2.91-.48 4.09-1.36l3.68 3.68c.19.17.43.25.68.25.25 0 .49-.11.66-.29.18-.18.28-.41.29-.66 0-.25-.08-.49-.25-.68ZM1.95 6.82c0-.96.29-1.91.82-2.71.54-.8 1.3-1.43 2.19-1.8.89-.37 1.87-.47 2.82-.28.95.19 1.81.65 2.5 1.33a4.81 4.81 0 0 1 1.33 2.5c.19.95.09 1.93-.28 2.82a4.87 4.87 0 0 1-1.8 2.19c-.8.54-1.74.82-2.71.82-1.29 0-2.53-.52-3.45-1.43a4.882 4.882 0 0 1-1.43-3.45Z" />
      </symbol>
    </svg>
  );
};

export default Icons;
