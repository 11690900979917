
import { useConsultantsForSearchBarContext } from "../search/ConsultantsForSearchBarContext";
import ProfileTab from "./ProfileTab.component";

const AppNavScroll = () => {
  const { consultantsForSearchBar } = useConsultantsForSearchBarContext();

  return (
    <div className="layout-nav-scroll">
      <ul className="layout-nav">
        <li>
          {consultantsForSearchBar.map((consultantEntry) => (
            <ProfileTab consultantData={consultantEntry}></ProfileTab>
          ))}
        </li>
      </ul>
    </div>
  );
};

export default AppNavScroll;
