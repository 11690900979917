import { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import ReactMarkdown from "react-markdown";

import avatarPlaceholder from "./avatar-placeholder.svg";
import { getAnswerToQuestion, getDataOfSingleConsultant } from "../../api/api";
import { SimpleConsultantProfile } from "../search/ConsultantsForSearchBarContext";
import ChatBox from "./ChatBox.component";

import { QuestionContext } from "./QuestionContext";
import { OfferContext } from "../layout/OfferContext";
import { ChatContext } from "../layout/ChatContext";
import { useConsultantsForSearchBarContext } from "../search/ConsultantsForSearchBarContext";

export function invariant(value: unknown): asserts value {
  if (value) {
    return;
  }

  throw new Error("Invariant violation");
}

const Profile = () => {
  const { id } = useParams();
  invariant(id);

  const [question, setQuestion] = useState("");
  const [summary, setSummary] = useState("");
  const [formFieldValue, setFormFieldValue] = useState(question);
  const { offer, setOffer } = useContext(OfferContext);
  const { chatHistory, setChatHistory } = useContext(ChatContext);
  const { consultantsForSearchBar } = useConsultantsForSearchBarContext();

  const [consultantForOverview, setConsultantForOverview] =
    useState<SimpleConsultantProfile>({
      name: "",
      id: "",
      summary: "",
      title: "",
      score: 0,
      bestMatch: false,
    });

  const triggerBestMatchQuestion = (questionToAsk: string) => {
    if (
      (typeof chatHistory[id] === "undefined" &&
        consultantsForSearchBar[0]["id"] === id &&
        consultantsForSearchBar[0]["bestMatch"] === true &&
        questionToAsk === "") ||
      questionToAsk !== ""
    ) {
      getAnswerToQuestion({
        questionText: questionToAsk,
        summary: consultantForOverview.summary,
        offer: offer,
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          if (!chatHistory[id]) {
            chatHistory[id] = [];
          }
          setChatHistory({
            ...chatHistory,
            [id]: [data[0], ...chatHistory[id]],
          });
        });
    } else {
    }
  };

  useEffect(() => {
    getDataOfSingleConsultant(id)
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setConsultantForOverview(data[0]);
      })
      .then(() => {
        triggerBestMatchQuestion("");
      });
  }, [id]);

  const handleSubmitForm = (e: any) => {
    e.preventDefault();
    triggerBestMatchQuestion(formFieldValue);
    setQuestion(formFieldValue);
    setSummary(consultantForOverview.summary);
    setOffer(offer);
    setFormFieldValue("");
  };

  return (
    <>
      <div className="page-nav">
        <Link to="/" className="btn-link --underline">
          <svg className="svg-use">
            <use href="#icon-arrow-back"></use>
          </svg>
          Back to offer
        </Link>
      </div>

      <div className="app-main-grid">
        <div className="app-main-content">
          <div className="profile-card">
            <picture className="profile-card-img">
              <img src={avatarPlaceholder} alt=""></img>
            </picture>
            <div className="profile-card-main">
              <h2 className="profile-card-name">
                {consultantForOverview.name}
              </h2>
              <div className="profile-card-position">
                {consultantForOverview.title}, Germany
              </div>
              <p className="profile-card-details">
                <Link to="mailto:d.mustaine@cbtw.com" target="_blank">
                  {consultantForOverview.name.toLowerCase() + "@cbtw.com"}
                </Link>
                <br />
                <a href="tel:0033473856315" target="_blank">
                  0473 856 315
                </a>
                <br />
                <a target="_blank">Contact in teams</a>
              </p>
            </div>
            <div className="profile-card-aside">
              <h3>Office</h3>
              <p>
                MUNICH <br />
                Neuturmstraße 5<br />
                80331 Munich
              </p>
            </div>
          </div>

          <div className="text-block">
            <h2>Profile summary</h2>
            <div>
              <ReactMarkdown>{consultantForOverview.summary}</ReactMarkdown>
            </div>
          </div>
        </div>

        <QuestionContext.Provider
          value={{
            question,
            setQuestion,
            summary,
            setSummary,
          }}
        >
          <div className="chatbot-container">
            <form
              className="chatbot-header chatbot-search"
              method="post"
              onSubmit={handleSubmitForm}
            >
              <input
                type="text"
                className="input"
                placeholder="Ask anything about the profile..."
                value={formFieldValue || ""}
                onChange={(e) => setFormFieldValue(e.target.value)}
              ></input>

              <button type="submit" className="chatbot-btn">
                <svg className="svg-use">
                  <use href="#icon-arrow-to"></use>
                </svg>
              </button>
            </form>

            <ChatBox></ChatBox>
          </div>
        </QuestionContext.Provider>
      </div>
    </>
  );
};

export default Profile;
