import { ChatEntry } from "./ChatBox.component";

const ChatBoxEntry = (props: { chatEntry: ChatEntry }) => {
  return (
    <div className="chatbot-entry">
      <div className="chatbot-ask">{props.chatEntry.question}</div>
      <div className="chatbot-answer">{props.chatEntry.answer}</div>
    </div>
  );
};

export default ChatBoxEntry;
