import { createContext, useContext } from "react";

export type SimpleConsultantProfile = {
  name: string;
  title: string;
  summary: string;
  id: string;
  score: number;
  bestMatch: boolean;
};

export type ConsultantsForSearchBarContextType = {
  consultantsForSearchBar: SimpleConsultantProfile[];
  setConsultantsForSearchBar: (value: []) => void;
};

export const ConsultantsForSearchBarContext = createContext<ConsultantsForSearchBarContextType>({
    consultantsForSearchBar: [],
    setConsultantsForSearchBar: () => {},
})

export const useConsultantsForSearchBarContext = () =>
  useContext(ConsultantsForSearchBarContext);