import { createContext, useContext } from "react";

export type QuestionType = {
  question: string;
  summary: string;
  setQuestion: (value: string) => void;
  setSummary: (value: string) => void;
};

export const QuestionContext = createContext<QuestionType>({
  question: "",
  summary: "",
  setQuestion: () => {},
  setSummary: () => {}
});

export const useQuestionContext = () => useContext(QuestionContext);
