import { createContext, useContext } from "react";

export type ChatContextType = {
  chatHistory: {
    [profileId: string]: { question: string; answer: string }[];
  };
  setChatHistory: (value: {}) => void;
};

export const ChatContext = createContext<ChatContextType>({
  chatHistory: {},
  setChatHistory: () => {},
});

export const useChatHistoryContext = () => useContext(ChatContext);
