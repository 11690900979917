export type OfferMessage = {
    offerText: string;
};

export type QuestionAnswerContent = {
    questionText: string;
    summary: string;
    offer: string;
}

const address = ""

export async function getAnswerToQuestion(questionContext: QuestionAnswerContent): Promise<Response> {
    console.log("---Getting answer to given question---")
    const response = await fetch (address + "/api/qna", {
        method: "POST",
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "mode": "no-cors"
        },
        body: JSON.stringify(questionContext)
    });
    return response
}

export async function getBestMatches(offer: OfferMessage): Promise<Response> {
    console.log("---Getting best matches for given offer---")
    const response = await fetch(address + "/api/find_best_matches", {
        method: "POST",
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "mode": "no-cors"
        },
        body : JSON.stringify(offer)
    });

    return response;
}

export async function getDataOfAllConsultants(): Promise<Response> {
    console.log("---Getting data of all Consultant---")
    const response = await fetch(address + "/api/consultants", {
        method: "GET",
        headers: {
            "Content-Type": "application/json",
        }
    });
    return response
}


export async function getDataOfSingleConsultant(id: string): Promise<Response> {
    console.log("---Getting data of single consultant---")
    const response = await fetch (address + "/api/consultant?id=" + id, {
        method: 'GET',
        headers: {
            "Content-Type": "application/json"
        }
    });
    return response
}
