import React from "react";
import ReactDOM from "react-dom/client";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { initializeIcons } from "@fluentui/react";

import Layout from "./pages/layout/Layout";
import Search from "./pages/search/search";
import Profile from "./pages/profile/profile";


initializeIcons();

export default function App(){
    return (
        <BrowserRouter>
            <Routes>
                <Route element={<Layout />}>
                    <Route path="/" element={<Search />} /> 
                    <Route path="/profile/:id" element={<Profile />} /> 
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);
