import "./Layout.css";
import Icons from "./Icons.component";
import Header from "./Header.component";
import AppNav from "./AppNav.component";
import AppMain from "./AppMain.component";
import { OfferContext } from "./OfferContext";
import { useState } from "react";
import { ChatContext } from "./ChatContext";
import { ConsultantsForSearchBarContext } from "../search/ConsultantsForSearchBarContext";

const Layout = () => {
  const [offer, setOffer] = useState("");
  const [consultantsForSearchBar, setConsultantsForSearchBar] = useState([]);
  const [profileChatHistory, setProfileChatHistory] = useState({});

  return (
    <>
      <OfferContext.Provider value={{ offer, setOffer }}>
        <ConsultantsForSearchBarContext.Provider
          value={{ consultantsForSearchBar, setConsultantsForSearchBar }}
        >
          <ChatContext.Provider
            value={{
              chatHistory: profileChatHistory,
              setChatHistory: setProfileChatHistory,
            }}
          >
            <Icons />
            <Header />
            <AppNav />
            <AppMain />
          </ChatContext.Provider>
        </ConsultantsForSearchBarContext.Provider>
      </OfferContext.Provider>
    </>
  );
};

export default Layout;
