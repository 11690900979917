import AppNavSearch from "./AppNavSearch.component";
import AppNavScroll from "./AppNavScroll.component";
import { useContext, useEffect, useState } from "react";
import { useConsultantsForSearchBarContext } from "../search/ConsultantsForSearchBarContext";
import { OfferContext, useOfferContext } from "./OfferContext";
import { getDataOfAllConsultants } from "../../api/api";

const AppNav = () => {
  const [searchInput, setSearchInput] = useState("");
  const offerContext = useOfferContext();
  const { setConsultantsForSearchBar } =
    useConsultantsForSearchBarContext();

  useEffect(() => {
    if (offerContext.offer === "") {
      getDataOfAllConsultants()
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          setConsultantsForSearchBar(data);
        });
    }
  }, []);

  return (
    <div className="app-nav">
      <AppNavSearch searchInput={searchInput} setSearchInput={setSearchInput} />
      <AppNavScroll />
    </div>
  );
};

export default AppNav;
