import { Link } from "react-router-dom";
import cbtwLogo from "../../../assets/cbtw-logo.svg";

const Header = () => {
  return (
    <header className="header">
      <Link to="/" className="app-logo">
        <img className="cbtw-logo" src={cbtwLogo}></img>
        <h1 className="app-logo-title">
          Tech Profile <br />
          Matching Assistant
        </h1>
      </Link>

      <div className="app-credits">Powered by Positive Thinking Company</div>
    </header>
  );
};

export default Header;
