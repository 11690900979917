import illuDocument from "./illu-document.svg";
import demoExplanation1 from "./ProfileMatcher_Explanation1.svg";
import demoExplanation2 from "./ProfileMatcher_Explanation2.svg";
import demoUseCaseCS from "./ProfileMatcher_UseCaseCustomerService.svg";
import demoUseCaseESG from "./ProfileMatcher_UseCaseESG.svg";
import demoUseCaseLegal from "./ProfileMatcher_UseCaseLegal.svg";
import demoUseCaseSales from "./ProfileMatcher_UseCaseSales.svg";

import { useOfferContext } from "../layout/OfferContext";
import { useState } from "react";
import { useChatHistoryContext } from "../layout/ChatContext";
import { useConsultantsForSearchBarContext } from "./ConsultantsForSearchBarContext";
import { getBestMatches } from "../../api/api";

const Search = () => {
  const { offer, setOffer } = useOfferContext();
  const { setChatHistory } = useChatHistoryContext();
  const { setConsultantsForSearchBar } = useConsultantsForSearchBarContext();
  const [formFieldValue, setFormFieldValue] = useState(offer);

  const handleSubmitForm = (e: any) => {
    e.preventDefault();
    setOffer(formFieldValue);
    if (formFieldValue !== "") {
      getBestMatches({ offerText: formFieldValue })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          setConsultantsForSearchBar(data);
        });
    }
    setChatHistory({});
  };

  return (
    <>
      <div style={{ textAlign: "right" }}>
        <picture className="app-icon">
          <img className="illu-document" src={illuDocument}></img>
        </picture>
      </div>
      <form className="app-form" method="post" onSubmit={handleSubmitForm}>
        <h2>Search by offer</h2>
        <textarea
          className="--large"
          name="offerContent"
          placeholder="Paste your description here..."
          value={formFieldValue || ""}
          onChange={(e) => setFormFieldValue(e.target.value)}
        ></textarea>
        <div className="btn-list">
          <button type="submit" className="btn">
            Find matching profiles
          </button>
        </div>
      </form>
      <div className="app-description text-block">
        <div className="app-description-explanation-main-headline">
          With this AI-powered tool you can easily:
        </div>
        <div className="app-description-explanation">
          <div className="app-description-explanation-element">
            <img
              className="app-description-explanation-usecase-image"
              src={demoExplanation1}
            ></img>
            <div className="app-description-explanation-usecase-sector-headline">
              Human Resources
            </div>
            <br />
            <div className="app-description-explanation-description">
              Find the best matches between employee’s skill sets (CVs) and job
              positions
            </div>
          </div>
          <div className="app-description-explanation-element">
            <img
              className="app-description-explanation-usecase-image"
              src={demoExplanation2}
            ></img>
            <div className="app-description-explanation-usecase-sector-headline">
              Human Resources
            </div>
            <br />
            <div className="app-description-explanation-description">
              Ask specific questions about an employee’s skill set,
              qualifications or experience
            </div>
          </div>
        </div>
        <br />
        <p>
          <div className="app-description-explanation-usecase-introduction">
            This is just an outline of a tool we use internally to improve the
            fit of our consultants with customer requirements. The same logic
            and technologies can be applied to multiple{" "}
            <span className="app-description-explanation-usecase-introduction-semibold">
              business functions and industries:
            </span>
          </div>
          <br />
        </p>

        <div className="app-description-explanation">
          <div className="app-description-explanation-element">
            <img
              className="app-description-explanation-usecase-image"
              src={demoUseCaseSales}
            ></img>
            <div className="app-description-explanation-usecase-sector-headline">
              Sales
            </div>
            <div className="app-description-explanation-usecase-headline">
              Information Matching
            </div>
            <div className="app-description-explanation-description">
              Help customers to find the right product by matching customer
              requirements (size, color, features,…) and product specifications
              to find the best matches.
            </div>
          </div>
          <div className="app-description-explanation-element">
            <img
              className="app-description-explanation-usecase-image"
              src={demoUseCaseCS}
            ></img>
            <div className="app-description-explanation-usecase-sector-headline">
              Customer Service
            </div>
            <div className="app-description-explanation-usecase-headline">
              Communication & Information Request
            </div>
            <br />
            <div className="app-description-explanation-description">
              Handle technical support queries for products with an intelligent
              chatbot (e.g., provide troubleshooting assistance and
              product-related information).
            </div>
          </div>

          <div className="app-description-explanation-element">
            <img
              className="app-description-explanation-usecase-image"
              src={demoUseCaseLegal}
            ></img>
            <div className="app-description-explanation-usecase-sector-headline">
              Legal
            </div>
            <div className="app-description-explanation-usecase-headline">
              Knowledge Extraction & Risk Classification
            </div>
            <br />
            <div className="app-description-explanation-description">
              Help legal professionals in comparing and analyzing legal
              documents, such as contracts or agreements, to identify
              similarities, differences, or potential risks.
            </div>
          </div>

          <div className="app-description-explanation-element">
            <img
              className="app-description-explanation-usecase-image"
              src={demoUseCaseESG}
            ></img>
            <div className="app-description-explanation-usecase-sector-headline">
              ESG
            </div>
            <div className="app-description-explanation-usecase-headline">
              Knowledge Base
            </div>
            <div className="app-description-explanation-description">
              Provide support to experts dealing with customer inquiries
              regarding sustainability impacts and energy efficiency with an
              intelligent assistant that can access information from an existing
              knowledge database.
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Search;
