import { useContext, useEffect, useState } from "react";
import ChatBoxEntry from "./ChatBoxEntry.component";
import { QuestionContext } from "./QuestionContext";
import { getAnswerToQuestion } from "../../api/api";
import { OfferContext } from "../layout/OfferContext";
import { useParams } from "react-router-dom";
import { invariant } from "./profile";
import { ChatContext } from "../layout/ChatContext";
import { useConsultantsForSearchBarContext } from "../search/ConsultantsForSearchBarContext";

export type ChatEntry = {
  question: string;
  answer: string;
};

const ChatBox = () => {
  const { id } = useParams();
  invariant(id);

  const { question, summary } = useContext(QuestionContext);
  const { offer } = useContext(OfferContext);
  const { chatHistory, setChatHistory } = useContext(ChatContext);
  const { consultantsForSearchBar } = useConsultantsForSearchBarContext();

  useEffect(() => {
    if (offer === "" && question === "") {
      console.log("No offer and question provided");
      return;
    }
    
    if (typeof chatHistory[id] === "undefined" && consultantsForSearchBar[0]['id'] === id) {
      getAnswerToQuestion({
        questionText: question,
        summary: summary,
        offer: offer,
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          if (!chatHistory[id]) {
            chatHistory[id] = [];
          }
          setChatHistory({
            ...chatHistory,
            [id]: [data[0], ...chatHistory[id]],
          });
        });
    }
    
  }, [question]);

  return (
    <div className="chatbot-main">
      <div className="chatbot-scroll">
        {chatHistory[id] &&
          chatHistory[id].map((chatEntry) => (
            <ChatBoxEntry chatEntry={chatEntry}></ChatBoxEntry>
          ))}
      </div>
    </div>
  );
};

export default ChatBox;
